var app = {
	windowLoaded: function () {
		
	},
	windowResized: function () {
		var _main = this;
		// _main.getViewport();
	},
	
	events: function () {
		var _main = this;			

		$('.list-slider').slick({			
			dots: false,
			arrows: false,
			autoplay: true,
  			autoplaySpeed: 2000,
			pauseOnHover: false,
			pauseOnFocus: false
		});

	},
	init: function () {
		_main = this;
		_main.events();				
	},	
	debug: true
}

$(document).ready(function () {
	app.init();
});

$(window).on('load', function () {
	app.windowLoaded();
});

$(window).resize(function () {
	app.windowResized();
});

$(document).scroll(function () {
	
});
